<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editLagerType')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createLagerType')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>

                    <b-col cols="12 relative_tree">
                        <treeselect
                            :multiple="true"
                            :options="getCategoryOptionsTree()"
                            v-model="form.categories"
                            :matchKeys="matchKeys"
                            :alwaysOpen="true"
                            valueConsistsOf="ALL"
                        />
                    </b-col>
                    {{ form.categories }}
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Customer-Category-modal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
            },
            form: {},
            labelPrefix: 'settings.lagerType.label.',
            matchKeys: ['name'],
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getLocales', 'getDocuments']),
        ...mapGetters('LagerTypes', ['getLagerType']),
        ...mapGetters('Category', ['getCategoryOptionsTree']),
        shown() {
            const store = this.$store.dispatch('LagerTypes/fetchLagerType', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptionsTree')

            Promise.all([store, categories])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getLagerType()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('LagerTypes/saveLagerType', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>